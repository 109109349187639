<template>
    <default-layout fluid :title="$t('dashboard.dashboard').toString()">
        <!-- admin dashboard -->
        <template v-if="$isAdmin()">
            <page-subtitle2 :title="$t('enum.role.ROLE_ADMIN').toString()" class="mt-2" />
            <v-row>
                <applications-control-panel-widget />
                <applications-incoming-graph />
            </v-row>
            <page-subtitle :title="$t('timetables.timetables').toString()" class="mt-4" />
            <timetables-overview-widget />
        </template>
        <!-- parent dashboard -->
        <template v-if="$isParent()">
            <page-subtitle2 :title="$t('enum.role.ROLE_PARENT').toString()" class="mt-2" />
            <page-subtitle :title="$t('applications.applications').toString()" />

            <parent-applications-table ref="parent-applications-table" />
        </template>
        <!-- teacher dashboard -->
        <template v-if="$isTeacher()">
            <page-subtitle2 :title="$t('enum.role.ROLE_TEACHER').toString()" class="mt-2" />
            <v-card elevation="1" class="mt-4">
                <div class="text-center py-10">
                    <v-sheet class="pa-5 mb-8 rounded-circle d-inline-block gradient-background" elevation="2">
                        <v-icon class="text-center" size="50" color="white"> mdi-progress-clock </v-icon>
                    </v-sheet>
                    <v-card-text>
                        <p class="display-2">
                            {{ $t("dashboard.teachers.missing_title") }}
                        </p>
                        <span class="subtitle-1" v-html="$t('dashboard.teachers.missing_message')"> </span>
                        <p class="mt-10">
                            <v-btn
                                href="https://play.google.com/store/apps/details?id=cz.bestak.ekrouzek&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                                icon
                            >
                                <v-img
                                    max-width="200"
                                    alt="Nyní na Google Play"
                                    src="https://play.google.com/intl/en_us/badges/static/images/badges/cs_badge_web_generic.png"
                            /></v-btn>
                        </p>
                    </v-card-text>
                </div>
            </v-card>
        </template>
        <!-- super admin dashboard -->
        <template v-if="$isSuperAdmin()">
            <page-subtitle2 :title="$t('enum.role.ROLE_SUPER_ADMIN').toString()" class="mt-2" />
            <page-subtitle :title="$t('schools.schools').toString()" />

            <schools-table />
        </template>
    </default-layout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout";
import TimetablesOverviewWidget from "@/components/timetables/TimetablesOverviewWidget";
import ApplicationsIncomingGraph from "@/components/applications/ApplicationsIncomingGraph";
import ApplicationsControlPanelWidget from "@/components/applications/ApplicationsControlPanelWidget";
import PageSubtitle from "@/components/layout/PageSubtitle";
import Vue from "vue";
import PageSubtitle2 from "@/components/layout/PageSubtitle2";
import ParentApplicationsTable from "@/components/applications/ParentApplicationsTable";
import SchoolsTable from "@/components/school/SchoolsTable";

export default Vue.extend({
    name: "Dashboard",
    components: {
        SchoolsTable,
        ParentApplicationsTable,
        PageSubtitle2,
        PageSubtitle,
        ApplicationsControlPanelWidget,
        ApplicationsIncomingGraph,
        TimetablesOverviewWidget,
        DefaultLayout,
    },
});
</script>

<style lang="scss">
.v-stepper__header {
    box-shadow: none !important;
}
.gradient-background {
    background: linear-gradient(135deg, var(--v-primary-base), var(--v-secondary-base));
}
</style>
